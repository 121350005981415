<template>
  <div class="poster-page un_select">
    <div class="top-box">
      <div class="top">
        <van-search v-model="search" placeholder="搜索海报标题" shape="round" @search="onSearch" />
        <div class="search" @click="onSearch">搜索</div>
      </div>
    </div>
    <van-tabs v-model="active" @change="switchTab" swipeable>
      <!-- 不支持object.entries属性 需要配置babel  踩坑 -->
      <!-- <van-tab v-for="[k, v] in Object.entries(typeData)" :title="v" :key="k" :name="k"> -->
      <!-- 指定 name 属性的情况下，v-model 的值为当前标签的 name（此时无法通过索引值来匹配标签 -->
      <van-tab v-for="(v, k) in typeData" :title="v" :key="`type${k}`" :name="k">
        <van-list v-if="!isShowEmpty" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
          <div class="img-list">
            <div v-for="item in posterData" class="img-box" :key="item.id" v-permits.service @click="goEditPage(item)">
              <img :src="item.base_url" />
              <div class="img-title">
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div v-for="index in holdCount" class="pick" :key="`pick${index}}`"></div>
          </div>
        </van-list>
        <!-- 暂无数据 -->
        <div v-if="isShowEmpty" class="no-data">
          <img :src="require('../../assets/images/nodata.svg')" alt="" />
          <div>暂无海报</div>
        </div>
      </van-tab>
      <!-- 占位 -->
      <van-tab></van-tab>
    </van-tabs>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "poster",
  data() {
    return {
      active: "0", // 当前tab-->name值，即k值,也是type值
      pageNo: 1, // 当前页
      pageSize: 10,
      total: 0,
      posterData: [], // 海报数据
      typeData: { 0: "全部海报" },
      holdCount: 0, // 需要补充的占位元素个数
      loading: false, //上拉加载
      finished: false, //上拉加载完毕
      refreshing: false, //下拉刷新
      isShowEmpty: false,
      search: "", // 查询关键字
      // clientWidth: document.documentElement.clientWidth, // 屏幕宽度
      // test: { 0: "全部海报", 1: "节日类型", 2: "节日类型", 3: "节日类型", 4: "节日类型"}, // 海报类型
    };
  },
  mounted() {
    this.getType();
    this.getList();
    this.caculate();
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === "posterEdit") {
      sessionStorage.setItem("poster_active", this.active);
    } else {
      sessionStorage.removeItem("poster_active");
    }
    next();
  },

  methods: {
    // 请求海报类型
    getType() {
      this.$ajax("bills_type")
        .then(res => {
          var res = res.data;
          if (res.code === 0) {
            let data = res.data || {};
            // 「名片分享」不展示在海报库的tab
            // Object.entries(data).map(([k, v]) => {
            //   if (v === "名片分享") {
            //     delete data[k];
            //   }
            // });
            this.typeData = { ...this.typeData, ...data };
            let _active = sessionStorage.getItem("poster_active");
            if (_active) {
              // 从编辑页回列表页时，需要定位之前点击的tab
              // 注意点：需要等tab数据渲染完毕后，才能根据active去定位
              this.$nextTick(() => {
                this.active = _active;
              });
            }
          } else {
            this.$toast("获取海报类型失败");
            this.typeData = { ...this.typeData };
          }
        })
        .catch(err => {
          this.$toast("获取海报类型失败");
          console.error(err);
        });
    },

    /**
     * 请求列表数据
     * param: bills_type-海报类型 title-海报标题
     */
    getList() {
      this.loading = true;
      let _active = sessionStorage.getItem("poster_active") || this.active;
      this.$ajax("bills_list", {
        page_index: this.pageNo,
        page_size: this.pageSize,
        ...(_active ? { bills_type: _active } : ""),
        ...(this.search ? { title: this.search } : ""),
      })
        .then(res => {
          var res = res.data;
          if (res.code === 0) {
            this.total = res.data.total;
            this.posterData = this.posterData.concat(res.data.list);
            this.pageNo++;
            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (this.posterData.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.$toast("网络错误");
            this.posterData = [];
            this.isShowEmpty = true;
          }
        })
        .catch(err => {
          this.$toast("网络错误");
          this.posterData = [];
          this.isShowEmpty = true;
          this.loading = false;
        });
    },

    //上拉加载
    onLoad() {
      this.getList();
    },

    // 搜索 在全部类型下展示搜索结果
    // 修改active,会进switchTab方法
    onSearch() {
      this.active = 0;
    },

    // 切换tab
    switchTab() {
      this.pageNo = 1;
      this.finished = false;
      this.posterData = [];
      sessionStorage.removeItem("poster_active"); // getlist前清空session
      this.getList();
    },

    goEditPage({ base_url, bills_type, id }) {
      this.$router.push({
        name: "posterEdit",
        query: { base_url, bills_type, id },
      });
    },

    // space-between 最后一行的显示问题
    caculate() {
      const DIV_WIDTH = 163;
      const DIV_MARGIN = 0;
      const total = 10; // 数据总量
      const W = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const count_in_row = parseInt((W || 1080) / (DIV_WIDTH + DIV_MARGIN)); // 计算出一行最多放几个 div
      const I = parseInt(total % count_in_row);
      const need_placeholder_count = I ? count_in_row - I : 0;

      this.holdCount = parseInt(need_placeholder_count);
    },
  },
};
</script>

<style lang="scss" scoped>
$imgW: 3.28rem;
$imgH: 6.26rem;
$fontColor: #333333;
.poster-page {
  width: 100%;
  position: relative;
  .top-box {
    height: 1.12rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    // TODO: fixed布局，宽度超出问题未解决，未适配pc端
    .top {
      display: flex;
      width: 100%;
      max-width: 480px;
      height: 1.12rem;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      z-index: 100;
      padding: 0.16rem 0.32rem;
      box-sizing: border-box;
      .search {
        font-size: 0.28rem;
        font-weight: 500;
        color: $fontColor;
        line-height: 0.48rem;
      }
    }
  }
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // 避免模板数量少时，行间距过大
  align-content: flex-start;
  padding: 0 0.32rem;
  // TODO:避免模板数量少，未撑满页面时，滑动tab不切换的问题
  // height: calc(100vh - 2rem);
  color: $fontColor;
  font-size: 0.28rem;
  overflow-y: auto;
  box-sizing: border-box;
  .img-box {
    position: relative;
    width: $imgW;
    height: $imgH;
    margin-bottom: 0.24rem;
    img {
      width: $imgW;
      height: 5.78rem;
      border-radius: 0.16rem;
      overflow: hidden;
    }
    .img-title {
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .pick {
    content: "";
    width: $imgW;
  }
  // 去掉苹果手机H5横向滚动条
  &::-webkit-scrollbar {
    display: none;
  }
}
.load-more {
  width: 100%;
  height: 0.8rem;
}
.no-data {
  line-height: 0.48rem;
  // 避免模板数量少，未撑满页面时，滑动tab不切换的问题
  height: calc(100vh - 2rem);
  font-size: 0.32rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30%;
  box-sizing: border-box;
  img {
    width: 1.76rem;
    height: 1.76rem;
    margin-bottom: 0.32rem;
  }
}
</style>

<style lang="scss">
$fontColor: #333333;
.poster-page {
  .van-tabs__wrap {
    position: sticky;
    top: 1.12rem;
    width: 100%;
    max-width: 480px;
    height: 0.88rem;
    padding: 0 0.32rem;
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    color: #666666;
    background-color: #fff;
    z-index: 100;
    box-sizing: border-box;
    .van-tabs__line {
      width: 0.48rem;
      height: 0.04rem;
      background-color: #1472ff;
      border-radius: 0.02rem;
      margin-bottom: 0.08rem;
    }
    .van-tabs__nav--complete {
      padding-right: 0;
      padding-left: 0;
    }
    .van-tab {
      font-size: 0.28rem;
      line-height: 0.4rem;
      padding: 0 0 !important;
      &:not(:last-child) {
        margin-right: 0.46rem;
      }
      .van-tab__text--ellipsis {
        white-space: nowrap;
      }
    }
    .van-tab--active {
      font-weight: 600;
      color: $fontColor;
    }
  }
  .van-tabs__content {
    padding-top: 0.24rem;
    background-color: #f5f5f5;
  }
  .van-loading {
    margin: 0 auto;
  }
  .van-search {
    width: 5.98rem;
    height: 0.8rem;
    padding: 0 0;
    background: #f5f5f5;
    border-radius: 0.4rem;
  }
}
</style>
